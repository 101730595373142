import Image from 'next/image';

import { Seo } from '@/components/Seo';
import { Typography } from '@/components/_atoms/Typography';
import { LinkButton } from '@/components/_molecules/Button/LinkButton';

import image404 from './assets/404.jpg';

export const Custom404 = () => {
  return (
    <>
      <Seo nofollow noindex />
      <div className="flex min-h-[50vh] flex-col items-center justify-center py-6 text-center">
        <Image unoptimized src={image404} alt="Błąd 404" />
        <Typography as="h1" bold variant="header_l" id="404.header" className="mt-4" />
        <Typography variant="body_m" id="404.text" className="mt-4" />
        <div className="mt-10">
          <LinkButton href="/" variant="primary" textId="404.button" />
        </div>
      </div>
    </>
  );
};
