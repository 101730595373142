import { Custom404 } from '@/screens/Custom404';
import { getMessages } from '@/utils/messages.utils';

export default Custom404;

export const getStaticProps = async () => {
  return {
    props: {
      messages: await getMessages(),
    },
  };
};
